solumaxSms
	.controller('SettingShowController', function (
		SettingModel) {

		var vm = this

		vm.gateways = [
			{
				type: 'old_sms_gateway',
				name: 'Old SMS Gateway'
			},
			{
				type: 'sms_gateway_ultimate',
				name: 'SMS Gateway Ultimate'
			},
			{
				type: 'traccar_sms_gateway',
				name: 'Traccar SMS Gateway'
			}
		]

		vm.store = function (setting) {

			setting.unique = true

			SettingModel.store(setting)
				.then(function (res) {
					setting = res.data.data
				})
		}

		SettingModel.index({ object_types: 'SLACK_WEBHOOK,GATEWAY,CAP' })
			.then(function (res) {

				vm.setting = {
					slack: _.find(res.data.data, { object_type: 'SLACK_WEBHOOK' }) || { object_type: 'SLACK_WEBHOOK' },
					gateway: _.find(res.data.data, { object_type: 'GATEWAY' }) || { object_type: 'GATEWAY' },
					cap: _.find(res.data.data, { object_type: 'CAP' }) || { object_type: 'CAP' },
				}
			})
	})