solumaxSms
	.controller('SmsIndexController', function(
		$state,JwtValidator,LinkFactory,
		SmsModel) {

		var vm = this

		$('.date').datepicker({dateFormat: "yy-mm-dd"});

		vm.filter = {
			paginate: 20
		}

		if ($state.params.phoneNumber) {
			vm.filter.phoneNumber = $state.params.phoneNumber
		}

		vm.download = function(filter){
			filter.jwt = JwtValidator.encodedJwt

		window.open(LinkFactory.sms.report.sms + '?' + $.param(filter))
		}

		vm.load = function(page) {

			if (page) {
				vm.filter.page = page
			}

			SmsModel.index(vm.filter)
			.then(function(res) {
				vm.smses = res.data.data
				vm.meta = res.data.meta
			})
		}
		vm.load()
	})