solumaxSms
	.directive('smsList', function(
		SmsModel) {

		return {
			templateUrl: 'app/sms/listDirective/smsListDirective.html',
			scope: {
				smses: '='
			},
			link: function(scope, elem, attrs) {
			}
		}
	})