solumaxSms
	.directive('smsShow', function(
		SmsModel) {

		return {
			templateUrl: 'app/sms/showDirective/smsShowDirective.html',
			scope: {
				innerSms: '=sms',
				smsId: '@'
			},
			link: function(scope, asdf, attrs) {

				attrs.$observe('smsId', function(newValue, oldValue) {
					if (newValue) {
						SmsModel.get(newValue)
						.then(function(res) {
							scope.sms = res.data.data
							scope.innerSms = res.data.data
						})
					}
				})

				scope.$watch('innerSms', function(newValue, oldValue) {
					scope.sms = newValue
				})

				scope.send = function(sms) {

					SmsModel.send(sms)
					.then(function(res) {
						scope.sms = res.data.data
						scope.innerSms = res.data.data
					})
				}
			}
		}
	})