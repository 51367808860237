solumaxSms
	.factory('LinkFactory', function() {

		var hostname = window.location.hostname
		var env = 'dev'

		var domains = {
			accounts: 'https://accounts.xolura.com/',
			sms: window.location.protocol + '//' + window.location.host + '/'
		}

		var apps = {
			authentication: domains.accounts + 'views/user/',
			sms: {
				api: domains.sms + 'api/' ,
				report: domains.sms + 'report/'
			}
		}

		var urls = {

			authentication: {
				login: apps.authentication + 'authentication/login',
				tenantSelect: apps.authentication + 'token-exchange/tenant-select'
			},

			sms: {
				api: {
					sms: apps.sms.api + 'sms/',
				},
				report:{
					sms: apps.sms.report + 'sms/'
				}
			}

		}


		return urls
	})