solumaxSms
    .controller('SmsShowController', function(
        $state,
        SmsModel) {

        var vm = this

        vm.id = $state.params.id

        vm.reply = function(shownSms) {

            vm.replySms = {
                'number': shownSms['number'],
                messageable_app: 'Solumax.Sms',
                messageable_type: 'SmsReply',
                messageable_id: shownSms.id,
            }

            vm.replying = true
        }

        vm.loadPrevious = function(shownSms) {

            SmsModel.index({ number: shownSms.number })
                .then(function(res) {
                    vm.smses = res.data.data
                })
        }
    })
