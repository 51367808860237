var solumaxSms = angular
	.module('Solumax.Sms', ['ui.router', 'angular-jwt',
		'Solumax.Pagination',
		'Solumax.ErrorInterceptor', 'Solumax.JwtManager', 'Solumax.CsvUploader',
		'Solumax.TenantDatabaseConnection', 'Solumax.PageTitle', 'Solumax.Setting'])
	.factory('AppFactory', function() {

		var appFactory = {};

		appFactory.moduleId = '10350';

		return appFactory;
	})