solumaxSms
	.config(function($stateProvider, $urlRouterProvider) {

		$urlRouterProvider.otherwise('/index');

		$stateProvider
		.state('index', {
			url: '/index',
  			templateUrl: 'app/index/index.html',
  			controller: 'IndexController as ctrl',
  			requireLogin: false,
  			pageTitle: 'Solumax SMS'
		})

		.state('settingShow', {
			url: '/setting/show/:id',
  			templateUrl: 'app/setting/show/settingShow.html',
  			controller: 'SettingShowController as ctrl',
  			pageTitle: 'Solumax SMS'
		})

		.state('smsShow', {
			url: '/sms/show/:id',
  			templateUrl: 'app/sms/show/smsShow.html',
  			controller: 'SmsShowController as ctrl',
  			pageTitle: 'Solumax SMS'
		})
		.state('smsIndex', {
			url: '/sms/index/:unique/:phoneNumber',
  			templateUrl: 'app/sms/index/smsIndex.html',
  			controller: 'SmsIndexController as ctrl',
  			pageTitle: 'Solumax SMS'
		})
		.state('smsMassSend', {
			url: '/sms/massSend/',
  			templateUrl: 'app/sms/massSend/smsMassSend.html',
  			controller: 'SmsMassSendController as ctrl',
  			pageTitle: 'Solumax SMS'
		})

	})