solumaxSms
    .controller('SmsMassSendController', function(
        CsvUploader,
        SmsModel) {

        var vm = this

        vm.loadFile = function() {

            CsvUploader.parse(document.getElementById('csv-file').files[0])
                .then(function(data) {
                    processData(data.data)
                })
        }

        vm.sendAll = function() {

            vm.sent = true;

            CsvUploader.upload(vm.smses, { upload: upload })
        }

        vm.remove = function(sms) {
            _.remove(vm.sms, sms)
        }

        function processData(data) {

            vm.smses = _.map(data, function(data) {

                var sms = _.pick(data, ['number', 'message', 'note', 'messageable_app', 'messageable_type', 'messageable_id'])

                if (!sms.number) {
                    sms.status = 'Nomor telepon invalid'
                } else if (!sms.message) {
                    sms.status = 'Pesan belum diisi'
                } else {
                    sms.status = 'Ready'
                }

                return sms
            })

        }

        var upload = function(sms) {

            return SmsModel.send(sms)
                .then(function(res) {
                    sms.status = 'Berhasil diupload. ID: ' + _.toString(res.data.data.id)
                }, function(res) {
                    sms.status = 'Gagal'
                })

        }
    })
