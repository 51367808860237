solumaxSms
	.factory('SmsModel', function(
		$http,
		LinkFactory) {

		var smsModel = {}

		smsModel.index = function(params) {
			return $http.get(LinkFactory.sms.api.sms, {params: params})
		}

		smsModel.get = function(id, params) {
			return $http.get(LinkFactory.sms.api.sms + id, {params: params})
		}

		smsModel.send = function(sms, params) {
			return $http.post(LinkFactory.sms.api.sms + 'send/', sms, {params: params})
		}
		
		smsModel.receive = function(sms, params) {
			return $http.post(LinkFactory.sms.api.sms + 'receive/', sms, {params: params})
		}
		
		return smsModel

	})